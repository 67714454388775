<template>
    <div class="text-left">
        <b-form-group
            label="Nom de la configuration :"
            invalid-feedback="Veuillez saisir un nom"
            :state="Boolean(name) && null"
        >
            <b-form-input
                id="input-name"
                v-model="name"
                placeholder="Le nom de la configuration"
                :state="Boolean(name) && null"
                @change="onChange"
                size="sm"
                trim
            ></b-form-input>
        </b-form-group>

        <b-form-group
            label="Emprise géographique :"
            invalid-feedback="Veuillez selectionner une emprise"
        >
            <MapAreaSelector v-model="emprise" @input="onChange" />
        </b-form-group>

        <b-form-group label="Date de création :">
            <p class="ml-3 k-text-sm">{{ "Le " + createdAt || "Inconnu" }}</p>
        </b-form-group>
    </div>
</template>

<script>
import Vue from "vue";
import _ from "lodash";
import MapAreaSelector from "@/components/map/MapAreaSelector";

export default {
    components: {
        MapAreaSelector,
    },

    props: {
        applicationconfig: Object,
    },

    data: function() {
        if (this.applicationconfig) {
            return {
                name: this.applicationconfig.name,
                emprise:
                    this.applicationconfig.config &&
                    this.applicationconfig.config.emprise,
                createdAt: Vue.filter("dateFromISO")(
                    this.applicationconfig.createdAt,
                ),
            };
        } else {
            return {
                name: "",
                emprise: null,
                createdAt: "",
            };
        }
    },

    watch: {
        applicationconfig() {
            this.name = this.applicationconfig.name;
            this.emprise =
                this.applicationconfig.config &&
                this.applicationconfig.config.emprise;
            this.createdAt = Vue.filter("dateFromISO")(
                this.applicationconfig.createdAt,
            );
        },
    },

    methods: {
        getPayload() {
            return {
                item: "ApplicationConfig",
                data: {
                    name: this.name,
                    config: {
                        ...(this.applicationconfig.config || {}),
                        emprise: this.emprise,
                    },
                },
            };
        },

        onChange() {
            let changed = this.applicationconfig.name !== this.name;

            const emprise = _.get(this.applicationconfig, "config.emprise");
            if (emprise) {
                changed = changed || emprise.xmin !== this.emprise.xmin;
                changed = changed || emprise.xmax !== this.emprise.xmax;
                changed = changed || emprise.ymin !== this.emprise.ymin;
                changed = changed || emprise.ymax !== this.emprise.ymax;
            } else {
                changed = changed || this.emprise !== null;
            }
            this.$parent.$emit("form-change", changed);
        },

        onValidate(value) {
            this.$parent.$emit("validate", value);
        },
    },
};
</script>

<style lang="scss"></style>
