<template>
    <b-tab
        :title="title"
        :title-item-class="`${itemClass} k-status-${state}`"
        :disabled="state == 'disabled'"
        class="h-100 w-100"
        lazy
    >
        <div id="content-row">
            <perfect-scrollbar
                class="w-100 h-100 overflow-auto"
                @form-change="onChange"
                @validate="onValidate"
            >
                <slot />
            </perfect-scrollbar>
        </div>
        <div
            id="footer-row"
            class="d-flex flex-row-reverse justify-content-between align-items-center"
        >
            <b-button
                v-show="showNext"
                variant="outline-primary"
                size="sm"
                @click="onNext"
                :disabled="!isChildFormValid"
            >
                {{ textNext }}<b-icon-arrow-right-circle class="ml-1" />
            </b-button>
            <div v-show="showSave">
                <b-button
                    variant="outline-success"
                    size="sm"
                    @click="onSave"
                    :disabled="!isChildFormValid"
                >
                    Enregistrer
                </b-button>
                <span
                    class="warn-icon text-danger"
                    v-show="!isChildFormValid"
                    v-b-tooltip.hover.topleft.v-danger
                    title="Au moins un des champs du formulaire est invalide ou manquant"
                >
                    <b-icon-exclamation-circle-fill />
                </span>
                <span
                    class="warn-icon text-warning"
                    v-show="hasChildFormChanged"
                    v-b-tooltip.hover.topleft.v-warning
                    title="Toutes les modifications n'ont pas été sauvegardées"
                >
                    <b-icon-exclamation-circle-fill />
                </span>
            </div>
            <b-button
                v-show="showPrevious"
                variant="outline-secondary"
                size="sm"
                @click="onPrevious"
            >
                <b-icon-arrow-left-circle class="mr-1" />Retour
            </b-button>
            <div v-show="showBlank" style="width:80px"></div>
        </div>
    </b-tab>
</template>

<script>
export default {
    props: {
        title: String,
        state: String,
        itemClass: { type: String, default: "" },
        showPrevious: { type: Boolean, default: false },
        showSave: { type: Boolean, default: false },
        showNext: { type: Boolean, default: false },
        showBlank: { type: Boolean, default: false },
        textNext: { type: String, default: "Continuer" },
    },

    data: function() {
        return {
            isChildFormValid: true,
            hasChildFormChanged: false,
        };
    },

    methods: {
        onChange(value) {
            this.hasChildFormChanged = value;
        },

        onValidate(value) {
            this.isChildFormValid = value;
        },

        onPrevious() {
            this.$emit("previous");
        },

        onNext() {
            this.$emit("next");
        },

        onSave() {
            this.$emit(
                "save",
                this.$slots.default[0].componentInstance.getPayload() || {},
            );
            this.hasChildFormChanged = false;
        },
    },
};
</script>

<style lang="scss" scoped>
#content-row {
    width: 100%;
    height: calc(100% - 50px);
    padding: 5px;
}
#content-row > div {
    padding: 15px;
}
#footer-row {
    width: 100%;
    height: 50px;
    padding: 0 10px;
    background: #eeeeee;
    border-top: 1px solid #ccc;
    border-radius: 0 0 8px 0;
}

.warn-icon {
    font-size: 20px;
    vertical-align: middle;
    margin-left: 5px;
}
</style>
