<template>
    <b-container fluid>
        <b-form-row class="mt-2">
            <b-col cols="12">
                <LabelledInputText
                    label="Description"
                    placeholder="Description courte de l'instantané"
                    :required="true"
                    v-model="localArboSnapshot.description"
                    :forceValidation="true"
                    @validated="isDescriptionValid = $event"
                    @input="hasChanged = true"
                />
            </b-col>
        </b-form-row>

        <b-form-row>
            <b-col cols="12" class="text-right">
                <small class="font-italic" v-show="isEditMode">
                    <b-icon-info-circle />
                    Création le
                    {{ localArboSnapshot.createdAt | dateFromISO }} ~ Dernière
                    modification le
                    {{ localArboSnapshot.updatedAt | dateFromISO }}
                </small>
            </b-col>
        </b-form-row>

        <b-form-row class="mt-3">
            <b-col cols="12" class="justify-content-center d-flex">
                <b-button
                    class="m-auto"
                    variant="success"
                    @click="save"
                    :disabled="!isValid || !hasChanged"
                    >Enregistrer</b-button
                >
            </b-col>
        </b-form-row>
    </b-container>
</template>

<script>
import Vue from "vue";
import LabelledInputText from "@/components/ui/item/LabelledInputText";

const defaultArboSnapshot = {
    description: undefined,
};

export default {
    components: {
        LabelledInputText,
    },

    props: {
        projectid: { type: [String, Number], required: true },
        configid: { type: [String, Number], required: false },
        arbosnapshot: { type: Object, required: false },
    },

    data: function() {
        return {
            hasChanged: false,
            isEditMode: this.arbosnapshot != null,
            isDescriptionValid:
                this.arbosnapshot != null && this.arbosnapshot.description,
            localArboSnapshot: Vue.util.extend(
                {},
                this.arbosnapshot || defaultArboSnapshot,
            ),
        };
    },

    computed: {
        isValid() {
            return this.isDescriptionValid;
        },
    },

    methods: {
        async save() {
            if (this.isEditMode) {
                await this.$store.dispatch("arbosnapshot/updateArboSnapshot", {
                    ...this.localArboSnapshot,
                    projectId: this.projectid,
                });
            } else {
                await this.$store.dispatch("arbosnapshot/createArboSnapshot", {
                    ...this.localArboSnapshot,
                    projectId: this.projectid,
                    acaId: this.configid,
                });
            }
            this.$emit("saved");
            this.hasChanged = false;
        },
    },
};
</script>

<style></style>
