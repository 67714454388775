<template>
    <div :class="{ 'ml-4': depth > 0 }" v-show="visible">
        <div
            class="arbo-node-block-xs"
            :class="`k-color-menu-${depth}${data.menu ? ' pointer' : ''}`"
            @click="showChildren = !showChildren"
        >
            <div class="arbo-node-control-xs" v-if="data.menu">
                <b-icon-plus-square-fill v-show="!showChildren" />
                <b-icon-dash-square-fill v-show="showChildren" />
            </div>

            <span v-show="depth == 0">
                Structure de l'arborescence
            </span>
            <template v-for="(name, lang) in data.name">
                <span class="name-span" :key="lang">
                    <b :class="`flag:${lang}`" /> {{ name }}
                </span>
            </template>
        </div>
        <div v-if="data.menu">
            <template v-for="menu in data.menu">
                <ArboNode
                    :data="menu"
                    :key="menu.id"
                    :depth="depth + 1"
                    :visible="showChildren"
                />
            </template>
        </div>
    </div>
</template>

<script>
export default {
    name: "ArboNode",
    props: {
        depth: { type: Number, default: 0 },
        data: Object,
        visible: { type: Boolean, default: true },
    },
    data: function() {
        return {
            showChildren: false,
        };
    },
};
</script>

<style lang="scss" scoped>
.arbo-node-block-xs {
    margin: 1px;
    border: 1px solid #aaa;
    padding-left: 8px;
    border-radius: 6px;
    min-height: 35px;
    display: flex;
    align-items: center;
}

.arbo-node-control-xs {
    margin-right: 5px;
}
.arbo-node-control-xs > svg {
    color: #555;
    transition: ease 0.3s;
}
.arbo-node-control-xs > svg:hover {
    color: #888;
    transition: ease 0.3s;
}

.name-span {
    display: flex;
    align-items: center;
    background: #fff;
    padding: 2px 10px;
    margin: 3px;
    border-radius: 6px;
}
.name-span > [class*=" flag:"],
.name-span > [class^="flag:"] {
    height: 14px;
    width: 21px;
    margin-right: 5px;
}
</style>
