<template>
    <div class="accordion w-100 text-left" role="tablist">
        <template v-if="!arbosnapshots || !arbosnapshots.length">
            <i class="text-center my-3 d-block">Aucun instantané</i>
        </template>
        <template v-else v-for="snapshot in arbosnapshots">
            <b-card no-body :key="snapshot.id">
                <b-card-header
                    header-tag="header"
                    class="k-accordion-header pointer"
                    :class="{
                        'k-accordion-header-selected':
                            appconfigarbo.currentArboSnapshotId == snapshot.id,
                    }"
                    role="tab"
                    @click="onSelectAccordionRow(snapshot)"
                >
                    <b-icon-triangle-fill
                        class="k-accordion-arrow"
                        :class="{
                            selected: snapshot === selectedSnapshot,
                        }"
                    />
                    <div class="flex-fill">
                        <div class="d-flex justify-content-between">
                            <small class="k-secondary-fg"
                                >Instantané du
                                <b>{{ snapshot.createdAt | dateFromISO }}</b>
                            </small>
                            <small>
                                <b-badge
                                    class="k-badge-state"
                                    variant="primary"
                                    v-show="
                                        appconfigarbo.currentArboSnapshotId ==
                                            snapshot.id
                                    "
                                >
                                    actif
                                </b-badge>
                                <b-badge
                                    class="k-badge-state"
                                    :variant="states[snapshot.state].variant"
                                >
                                    {{ states[snapshot.state].label }}
                                </b-badge>
                            </small>
                        </div>
                        <div>
                            {{ snapshot.description }}
                        </div>
                    </div>
                </b-card-header>
                <b-collapse
                    :id="`accordion-${snapshot.id}`"
                    accordion="my-accordion"
                    role="tabpanel"
                    :visible="snapshot === selectedSnapshot"
                >
                    <b-card-body>
                        <PanelPreviewArboSnapshot
                            :projectid="projectid"
                            :arbosnapshot="snapshot"
                            :appconfigarbo="appconfigarbo"
                            @update="onArboSnapshotUpdate"
                            @changed="onArboSnapshotChanged"
                        />
                    </b-card-body>
                </b-collapse>
            </b-card>
        </template>

        <!-- Modals -->
        <ModalUpdateArboSnapshot
            :projectid="projectid"
            :arbosnapshot="selectedSnapshot"
            @saved="onArboSnapshotChanged"
        />
    </div>
</template>

<script>
import PanelPreviewArboSnapshot from "@/components/model/arbosnapshot/PanelPreviewArboSnapshot";
import ModalUpdateArboSnapshot from "@/components/model/arbosnapshot/ModalUpdateArboSnapshot";
export default {
    components: {
        PanelPreviewArboSnapshot,
        ModalUpdateArboSnapshot,
    },

    props: {
        projectid: [String, Number],
        arbosnapshots: Array,
        appconfigarbo: Object,
    },

    data: function() {
        return {
            selectedSnapshot: null,
            states: {
                0: { label: "vierge", variant: "secondary" },
                1: { label: "prêt", variant: "success" },
                2: { label: "archivé", variant: "warning" },
            },
        };
    },

    watch: {
        arbosnapshots() {
            // Réouverture d'un panneau ouvert
            if (this.arbosnapshots && this.selectedSnapshot) {
                this.selectedSnapshot = this.arbosnapshots.find(
                    a => a.id === this.selectedSnapshot.id,
                );
            }
        },
    },

    methods: {
        onSelectAccordionRow(snapshot) {
            if (this.selectedSnapshot === snapshot) {
                this.selectedSnapshot = null;
            } else {
                this.selectedSnapshot = snapshot;
            }
        },

        onArboSnapshotUpdate() {
            this.$bvModal.show("ModalUpdateArboSnapshot");
        },

        onArboSnapshotChanged() {
            this.$emit("changed");
        },
    },
};
</script>

<style lang="scss">
.k-accordion-header {
    padding: 8px 5px;
    min-height: 50px;
    display: flex;
    align-items: center;
}

.k-accordion-header-selected {
    background: #e5e5e5;
}

.k-accordion-arrow {
    color: #bbb;
    margin: 0 10px;
    padding: 2px 4px 4px 4px;
    width: 24px;
    height: 24px;
    border-radius: 12px;
    transform: rotate(90deg);
    transition: transform 0.3s;
}

.k-accordion-arrow > * {
    filter: drop-shadow(0px -1px 0 #000);
}

.k-accordion-arrow.selected {
    transform: rotate(180deg);
    color: #999;
    transition: transform 0.3s;
}

.k-badge-state {
    width: 50px;
    margin: 1px;
    font-weight: normal;
}
</style>
