<template>
    <div>
        <div class="section-title-sm k-secondary-fg">
            Informations :
        </div>
        <div class="mx-2">
            <div v-show="arbosnapshot.state == 0">
                Aucune information
            </div>
            <div v-show="arbosnapshot.state == 1">
                <div>
                    Taille de la structure :
                    <b>{{ arbosnapshot.structureLength }}</b> octets
                </div>
                <div>
                    Taille des sources de données :
                    <b>{{ arbosnapshot.dataLength }}</b> octets
                </div>
                <div>
                    Taille des styles :
                    <b>{{ arbosnapshot.styleLength }}</b> octets
                </div>
            </div>
            <div v-show="arbosnapshot.state == 2">
                <div>
                    Taille de l'archive :
                    <b>{{ arbosnapshot.zipLength }}</b> octets
                </div>
            </div>
        </div>

        <div class="section-title-sm k-secondary-fg">
            Aperçu :
        </div>
        <div class="mx-2">
            <div v-show="arbosnapshot.state != 1">
                Non disponible
            </div>
            <div v-show="arbosnapshot.state == 1">
                <TreeArboBasic :structure="arbosnapshot.structure" />
            </div>
        </div>

        <div class="section-title-sm k-secondary-fg">
            Actions disponibles :
        </div>
        <div class="d-flex justify-content-around flex-wrap">
            <b-button variant="primary" size="sm" @click="onArboSnapshotUpdate">
                <b-icon-gear-fill class="mr-1" />
                Editer
            </b-button>
            <b-button
                variant="primary"
                size="sm"
                v-show="arbosnapshot.state == 0"
                @click="onArboSnapshotCommand('build')"
            >
                <b-icon-lightning-fill class="mr-1" />
                Initialiser
            </b-button>
            <b-button
                variant="primary"
                size="sm"
                v-show="arbosnapshot.state == 1"
                :disabled="isActive"
                @click="setActive"
            >
                <b-icon-bookmark-check-fill class="mr-1" />
                Définir comme actif
            </b-button>
            <b-button
                variant="warning"
                size="sm"
                v-show="arbosnapshot.state == 1"
                :disabled="isActive"
                @click="onArboSnapshotCommand('zip')"
            >
                <b-icon-lock-fill class="mr-1" />
                Archiver
            </b-button>
            <b-button
                variant="warning"
                size="sm"
                v-show="arbosnapshot.state == 2"
                @click="onArboSnapshotCommand('unzip')"
            >
                <b-icon-unlock-fill class="mr-1" />
                Désarchiver
            </b-button>
            <b-button
                variant="danger"
                size="sm"
                :disabled="isActive"
                @click="onArboSnapshotCommand('delete')"
            >
                <b-icon-trash-fill class="mr-1" />
                Supprimer
            </b-button>
        </div>
    </div>
</template>

<script>
import TreeArboBasic from "@/components/model/arbo/TreeArboBasic";
export default {
    components: {
        TreeArboBasic,
    },

    props: {
        projectid: [String, Number],
        arbosnapshot: Object,
        appconfigarbo: Object,
    },

    computed: {
        isActive() {
            return (
                this.appconfigarbo.currentArboSnapshotId == this.arbosnapshot.id
            );
        },
    },

    methods: {
        onArboSnapshotUpdate() {
            this.$emit("update");
        },

        async onArboSnapshotCommand(command) {
            await this.$store.dispatch(`arbosnapshot/${command}ArboSnapshot`, {
                id: this.arbosnapshot.id,
                projectId: this.projectid,
            });
            this.$emit("changed");
        },

        async setActive() {
            await this.$store.dispatch(`application/updateArboAppConfig`, {
                projectId: this.projectid,
                acaId: this.appconfigarbo.id,
                currentArboSnapshotId: this.arbosnapshot.id,
            });
            this.$emit("changed");
        },
    },
};
</script>

<style></style>
