<template>
    <div class="text-left">
        <b-form-group label="Application :" label-for="select-application">
            <template #description>
                <b-icon-exclamation-circle /> Le choix de l'application est
                définitif pour la configuration. Une fois votre choix
                enregistré, vous devrez créer une nouvelle configuration si vous
                voulez choisir une autre application.
            </template>
            <b-form-select
                id="select-application"
                v-model="selectedApplicationId"
                :options="applicationOptions"
                @change="onApplicationChanged"
                size="sm"
                :disabled="isEditMode"
            >
                <template #first>
                    <b-form-select-option :value="null" disabled>
                        -- Veuillez sélectionner une application --
                    </b-form-select-option>
                </template>
            </b-form-select>
        </b-form-group>

        <b-form-group label="Build :" label-for="select-build">
            <template #description>
                <b-icon-exclamation-circle /> Lors du changement de build,
                veuillez prendre en compte les évolutions de l'application et
                modifier les configurations et les styles en conséquence.
            </template>
            <b-form-select
                id="select-build"
                v-model="selectedBuildId"
                :options="buildOptions"
                :disabled="selectedApplicationId == null"
                size="sm"
            >
                <template #first>
                    <b-form-select-option :value="null" disabled>
                        -- Veuillez sélectionner un build --
                    </b-form-select-option>
                </template>
            </b-form-select>
        </b-form-group>
        <b-form-group v-show="selectedBuildId" label="Notes de version :">
            <div id="notes" class="k-text-sm">
                {{ selectedBuild.notes || "Aucune information" }}
            </div>
        </b-form-group>
    </div>
</template>

<script>
export default {
    props: {
        applicationconfig: Object,
        applicationbuild: Object,
    },

    data: function() {
        return {
            isEditMode: Boolean(this.applicationbuild),
            applications: [],
            selectedApplicationId: null,
            builds: [],
            selectedBuildId: null,
        };
    },

    computed: {
        applicationOptions() {
            return this.applications.map(app => {
                return {
                    text: `${app.name} - Template ${app.template}`,
                    value: app.id,
                };
            });
        },
        buildOptions() {
            return this.builds.map(b => {
                return {
                    text: `${b.version}`,
                    value: b.id,
                };
            });
        },
        selectedBuild() {
            if (this.selectedBuildId) {
                return this.builds.find(b => b.id === this.selectedBuildId);
            }
            return {};
        },
    },

    methods: {
        getPayload() {
            if (this.selectedBuildId) {
                if (
                    this.selectedBuildId !==
                    this.applicationconfig.ApplicationBuildId
                ) {
                    return {
                        item: "ApplicationConfig",
                        data: { buildId: this.selectedBuildId },
                    };
                }
            } else {
                return {
                    item: "error",
                    data: "Veuillez d'abord sélectionner un build",
                };
            }
        },

        async onApplicationChanged() {
            if (this.selectedApplicationId) {
                this.selectedBuildId = null;
                await this.fetchApplicationBuilds();
            }
        },

        async fetchApplicationBuilds() {
            this.builds = await this.$store.dispatch(
                "application/getApplicationBuilds",
                {
                    applicationId: this.selectedApplicationId,
                },
            );
        },
    },

    async beforeMount() {
        if (this.isEditMode) {
            this.selectedApplicationId = this.applicationbuild.ApplicationId;
            this.applications = [
                await this.$store.dispatch("application/getApplication", {
                    applicationId: this.selectedApplicationId,
                }),
            ];
            await this.fetchApplicationBuilds();
            this.selectedBuildId = this.applicationbuild.id;
        } else {
            this.applications = await this.$store.dispatch(
                "application/getApplications",
            );
        }
    },
};
</script>

<style lang="scss">
#notes {
    padding: 5px;
    text-align: justify;
    border-left: 1px solid $secondary-color;
}
</style>
