var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"accordion w-100 text-left",attrs:{"role":"tablist"}},[(!_vm.arbosnapshots || !_vm.arbosnapshots.length)?[_c('i',{staticClass:"text-center my-3 d-block"},[_vm._v("Aucun instantané")])]:_vm._l((_vm.arbosnapshots),function(snapshot){return [_c('b-card',{key:snapshot.id,attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"k-accordion-header pointer",class:{
                    'k-accordion-header-selected':
                        _vm.appconfigarbo.currentArboSnapshotId == snapshot.id,
                },attrs:{"header-tag":"header","role":"tab"},on:{"click":function($event){return _vm.onSelectAccordionRow(snapshot)}}},[_c('b-icon-triangle-fill',{staticClass:"k-accordion-arrow",class:{
                        selected: snapshot === _vm.selectedSnapshot,
                    }}),_c('div',{staticClass:"flex-fill"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('small',{staticClass:"k-secondary-fg"},[_vm._v("Instantané du "),_c('b',[_vm._v(_vm._s(_vm._f("dateFromISO")(snapshot.createdAt)))])]),_c('small',[_c('b-badge',{directives:[{name:"show",rawName:"v-show",value:(
                                    _vm.appconfigarbo.currentArboSnapshotId ==
                                        snapshot.id
                                ),expression:"\n                                    appconfigarbo.currentArboSnapshotId ==\n                                        snapshot.id\n                                "}],staticClass:"k-badge-state",attrs:{"variant":"primary"}},[_vm._v(" actif ")]),_c('b-badge',{staticClass:"k-badge-state",attrs:{"variant":_vm.states[snapshot.state].variant}},[_vm._v(" "+_vm._s(_vm.states[snapshot.state].label)+" ")])],1)]),_c('div',[_vm._v(" "+_vm._s(snapshot.description)+" ")])])],1),_c('b-collapse',{attrs:{"id":("accordion-" + (snapshot.id)),"accordion":"my-accordion","role":"tabpanel","visible":snapshot === _vm.selectedSnapshot}},[_c('b-card-body',[_c('PanelPreviewArboSnapshot',{attrs:{"projectid":_vm.projectid,"arbosnapshot":snapshot,"appconfigarbo":_vm.appconfigarbo},on:{"update":_vm.onArboSnapshotUpdate,"changed":_vm.onArboSnapshotChanged}})],1)],1)],1)]}),_c('ModalUpdateArboSnapshot',{attrs:{"projectid":_vm.projectid,"arbosnapshot":_vm.selectedSnapshot},on:{"saved":_vm.onArboSnapshotChanged}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }