<template>
    <div class="k-page-flex">
        <div class="k-page-title">
            <h1>Configuration de l'application</h1>
            <b-button size="sm" variant="outline-danger" v-b-modal.ModalDanger>
                <b-icon-trash /> Supprimer la configuration
            </b-button>
        </div>
        <div class="k-page-body overflow-hidden">
            <b-tabs
                v-model="tabIndex"
                class="k-tabs"
                content-class="k-nav-panel"
                active-nav-item-class="k-active-nav"
                nav-wrapper-class="k-nav-wrapper"
                nav-class="k-nav"
                vertical
                pills
            >
                <WrapperTab
                    title="Accueil"
                    :state="tabState['0']"
                    show-next
                    @next="tabIndex++"
                    show-blank
                >
                    <TabHelp />
                </WrapperTab>
                <WrapperTab
                    title="1 - Informations générales"
                    :state="tabState['1']"
                    show-save
                    @save="onSave"
                    show-next
                    @next="tabIndex++"
                    show-previous
                    @previous="tabIndex--"
                >
                    <TabGeneral :applicationconfig="appConfig" />
                </WrapperTab>

                <WrapperTab
                    title="2 - Application"
                    :state="tabState['2']"
                    show-previous
                    @previous="tabIndex--"
                    show-save
                    @save="onSave"
                    show-next
                    @next="tabIndex++"
                >
                    <TabApplicationBuild
                        :applicationconfig="appConfig"
                        :applicationbuild="appBuild"
                    />
                </WrapperTab>

                <WrapperTab
                    title="2.1 - Configuration"
                    item-class="lvl-2"
                    :state="tabState['2.1']"
                    show-previous
                    @previous="tabIndex--"
                    show-save
                    @save="onSave"
                    show-next
                    @next="tabIndex++"
                >
                    <TabConfiguration
                        :applicationconfig="appConfig"
                        :applicationbuild="appBuild"
                    />
                </WrapperTab>

                <WrapperTab
                    title="2.2 - Style"
                    item-class="lvl-2"
                    :state="tabState['2.2']"
                    show-previous
                    @previous="tabIndex--"
                    show-save
                    @save="onSave"
                    show-next
                    @next="tabIndex++"
                >
                    <TabStyle
                        :applicationconfig="appConfig"
                        :applicationbuild="appBuild"
                        :project="project"
                    />
                </WrapperTab>

                <WrapperTab
                    title="3 - Arborescences"
                    :state="tabState['3']"
                    show-previous
                    @previous="tabIndex--"
                    show-next
                    @next="tabIndex++"
                >
                    <TabArbos
                        :projectid="projectId"
                        :configid="configId"
                        :applicationbuild="appBuild"
                        :applicationarbos="appConfigArbos"
                        @save="updateArbos"
                    />
                </WrapperTab>

                <template v-for="(aca, index) in appConfigArbos">
                    <WrapperTab
                        :title="`3.${index + 1} - ${aca.Arbo.name}`"
                        :state="computeStatus(aca.currentArboSnapshotId, true)"
                        item-class="lvl-2"
                        show-previous
                        @previous="tabIndex--"
                        show-next
                        @next="tabIndex++"
                        :key="aca.ArboId"
                    >
                        <TabArbo
                            :projectid="projectId"
                            :configid="configId"
                            :appconfigarbo="aca"
                            @save="updateArbos"
                        />
                    </WrapperTab>
                </template>

                <WrapperTab
                    title="4 - Installations"
                    :state="tabState['4']"
                    show-previous
                    @previous="tabIndex--"
                    show-next
                    text-next="Terminer"
                    @next="goAdminProject"
                >
                    <TabInstallations
                        :projectid="projectId"
                        :configid="configId"
                        :appinstallations="appInstallations"
                        @save="updateInstallations"
                    />
                </WrapperTab>
            </b-tabs>
        </div>

        <ModalDanger
            @confirmed="onDeleteConfirmed"
            message="Voulez vous vraiment supprimer cette configuration?"
            details="La configuration et toutes les ressources associées à celle-ci seront définitivement supprimées."
        />
    </div>
</template>

<script>
import WrapperTab from "@/components/model/applicationconfig/tabs/WrapperTab.vue";
import TabHelp from "@/components/model/applicationconfig/tabs/TabHelp.vue";
import TabGeneral from "@/components/model/applicationconfig/tabs/TabGeneral.vue";
import TabApplicationBuild from "@/components/model/applicationconfig/tabs/TabApplicationBuild.vue";
import TabConfiguration from "@/components/model/applicationconfig/tabs/TabConfiguration.vue";
import TabStyle from "@/components/model/applicationconfig/tabs/TabStyle.vue";
import TabArbos from "@/components/model/applicationconfig/tabs/TabArbos.vue";
import TabArbo from "@/components/model/applicationconfig/tabs/TabArbo.vue";
import TabInstallations from "@/components/model/applicationconfig/tabs/TabInstallations.vue";
import ModalDanger from "@/components/modal/ModalDanger";
import notifier from "@/tools/notifier";

export default {
    components: {
        WrapperTab,
        TabHelp,
        TabGeneral,
        TabApplicationBuild,
        TabConfiguration,
        TabStyle,
        TabArbos,
        TabArbo,
        TabInstallations,
        ModalDanger,
    },

    data: function() {
        return {
            projectId: this.$route.params.projectId,
            configId: this.$route.params.configId,

            appConfig: null,
            appBuild: null,
            appConfigArbos: null,
            appInstallations: null,
            project: null,

            tabIndex: 0,
            firstPageState: "ok",
        };
    },

    computed: {
        tabState() {
            return {
                "1": this.firstPageState,
                "2": this.computeStatus(this.appBuild, this.appConfig),
                "2.1": this.computeStatus(
                    this.appConfig &&
                        this.appConfig.config &&
                        this.appConfig.config.app,
                    this.appBuild,
                ),
                "2.2": this.computeStatus(
                    this.appConfig && this.appConfig.style,
                    this.appBuild,
                ),
                "3": this.computeStatus(
                    this.appConfigArbos && this.appConfigArbos.length > 0,
                    this.appBuild,
                ),
                "4": this.computeStatus(
                    this.appInstallations && this.appInstallations.length > 0,
                    this.appBuild,
                ),
            };
        },
    },

    methods: {
        computeStatus(isOK = false, isTODO = false) {
            return (isOK && "ok") || (isTODO && "error") || "disabled";
        },

        async fetchProject() {
            this.project = await this.$store.dispatch("project/getProject", {
                projectId: this.projectId,
            });
        },

        async fetchApplicationConfig() {
            this.appConfig = await this.$store.dispatch(
                "application/getApplicationConfig",
                {
                    projectId: this.projectId,
                    configId: this.configId,
                },
            );
        },

        async fetchApplicationBuild() {
            if (this.appConfig && this.appConfig.ApplicationBuildId) {
                this.appBuild = await this.$store.dispatch(
                    "application/getApplicationBuild",
                    {
                        applicationBuildId: this.appConfig.ApplicationBuildId,
                    },
                );
            }
        },

        async fetchApplicationArbos() {
            if (this.appConfig && this.appConfig.ApplicationBuildId) {
                this.appConfigArbos = await this.$store.dispatch(
                    "application/getApplicationConfigArbos",
                    {
                        projectId: this.projectId,
                        configId: this.configId,
                    },
                );
            }
        },

        async fetchApplicationInstallations() {
            if (this.appConfig && this.appConfig.ApplicationBuildId) {
                this.appInstallations = await this.$store.dispatch(
                    "application/getInstallations",
                    {
                        projectId: this.projectId,
                        configId: this.configId,
                    },
                );
            }
        },

        async updateArbos() {
            await this.fetchApplicationArbos();
        },

        async updateInstallations() {
            await this.fetchApplicationInstallations();
        },

        async onSave({ item, data }) {
            switch (item) {
                case "ApplicationConfig":
                    await this.$store.dispatch(
                        "application/updateApplicationConfig",
                        {
                            projectId: this.projectId,
                            configId: this.configId,
                            ...data,
                        },
                    );
                    await this.fetchApplicationConfig();
                    if (data.buildId) {
                        await this.fetchApplicationBuild();
                        await this.fetchApplicationArbos();
                        await this.fetchApplicationInstallations();
                    }
                    break;
                case "error":
                    return notifier.error(data);
                default:
                    return;
            }
        },

        async onDeleteConfirmed() {
            await this.$store.dispatch("application/deleteApplicationConfig", {
                projectId: this.projectId,
                configId: this.configId,
            });
            this.$router.push({
                name: "AdminProject",
                params: { projectId: this.projectId },
            });
        },

        goAdminProject() {
            this.$router.push({
                name: "AdminProject",
                params: { projectId: this.projectId },
            });
        },
    },

    async beforeMount() {
        await this.fetchProject();
        await this.fetchApplicationConfig();
        await this.fetchApplicationBuild();
        await this.fetchApplicationArbos();
        await this.fetchApplicationInstallations();
        if (!this.appConfig) {
            this.firstPageState = "disabled";
            this.tabIndex = -1;
        }
    },
};
</script>

<style lang="scss">
.k-tabs {
    height: 100%;
    margin: 0 !important;
    flex-wrap: nowrap;
}
.k-nav-wrapper {
    height: 100%;
    width: 270px;
    padding: 0;
    border: 1px solid #ccc;
    background: #eeeeee;
    border-radius: 8px 0px 0px 8px;
}
.k-nav {
    text-align: left;
}
.k-nav > li {
    border-bottom: 1px solid #e0e0e0;
}

.k-status-bubble {
    content: "";
    position: absolute;
    right: 5px;
    margin-top: 5px;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    border: 1px solid #999;
}

.k-nav > li.k-status-ok::before {
    @extend .k-status-bubble;
    background-color: $secondary-color;
}
.k-nav > li.k-status-warn::before {
    @extend .k-status-bubble;
    background-color: #c7a445;
}
.k-nav > li.k-status-error::before {
    @extend .k-status-bubble;
    background-color: #c74545;
}
.k-nav > li.k-status-disabled::before {
    @extend .k-status-bubble;
    background-color: rgb(185, 185, 185);
}

.k-nav > li.lvl-2 > a {
    padding-left: 40px;
}

.k-active-nav {
    background-color: $primary-color !important;
    border-radius: 5px 0px 0px 5px !important;
}
.k-nav-panel {
    height: 100%;
    border: 1px solid #ccc;
    border-left: 0;
    border-radius: 0px 8px 8px 0px;
    padding: 0;
}
</style>
