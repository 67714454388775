<template>
    <b-modal
        id="ModalCreateArbo"
        title="Créer une arborescence"
        header-bg-variant="kalkin-1"
        header-text-variant="light"
        hide-footer
    >
        <FormArbo
            :projectid="projectid"
            :applicationbuild="applicationbuild"
            @saved="onSaved"
            class="mb-2"
        />
    </b-modal>
</template>
<script>
import FormArbo from "@/components/model/arbo/FormArbo";
export default {
    components: {
        FormArbo,
    },

    props: {
        projectid: { type: [String, Number], required: true },
        applicationbuild: { type: Object, required: false },
    },

    methods: {
        onSaved() {
            this.$bvModal.hide("ModalCreateArbo");
            this.$emit("saved");
        },
    },
};
</script>
