<template>
    <div class="k-flex-col">
        <label>Installations du projet :</label>

        <div
            class="k-card-1 k-flex-row"
            :class="{ selected: isInstallationUsed(install) }"
            v-for="install in installations"
            :key="install.id"
        >
            <div>
                <b class="text-kalkin-2">
                    {{ install.name }}
                </b>
                <small class="text-secondary">
                    {{ install.type }}
                </small>
            </div>
            <div>
                <b-button
                    v-if="!isInstallationUsed(install)"
                    size="xs"
                    variant="kalkin-1"
                    @click="onAddInstallation(install.id)"
                >
                    <b-icon-link /> Associer à l'application
                </b-button>
                <b-button
                    v-else
                    size="xs"
                    variant="danger"
                    @click="onDeleteInstallation(install.id)"
                >
                    <b-icon-x-circle /> Dissocier de l'application
                </b-button>
            </div>
        </div>

        <ModalDanger
            uid="TabInstallations.delete"
            @confirmed="onDeleteConfirmed"
            message="Voulez vous vraiment dissocier cette installation de l'application?"
        />
    </div>
</template>

<script>
import ModalDanger from "@/components/modal/ModalDanger";

export default {
    components: {
        ModalDanger,
    },

    props: {
        projectid: [String, Number],
        configid: [String, Number],
        appinstallations: { type: Array, required: true },
    },

    data: function() {
        return {
            installations: [],
            installationToRemove: null,
        };
    },

    computed: {
        usedInstallations() {
            return this.appinstallations.map(i => i.id);
        },
    },

    methods: {
        isInstallationUsed(installation) {
            return this.usedInstallations.includes(installation.id);
        },

        async onAddInstallation(installationId) {
            await this.$store.dispatch("application/addInstallation", {
                projectId: this.projectid,
                configId: this.configid,
                installationId: installationId,
            });
            this.$emit("save");
        },

        async onDeleteInstallation(installationId) {
            this.installationToRemove = installationId;
            this.$bvModal.show("TabInstallations.delete");
        },

        async onDeleteConfirmed() {
            await this.$store.dispatch("application/removeInstallation", {
                projectId: this.projectid,
                configId: this.configid,
                installationId: this.installationToRemove,
            });
            this.installationToRemove = null;
            this.$emit("save");
        },
    },

    async beforeMount() {
        this.installations = await this.$store.dispatch(
            "project/getInstallations",
            {
                projectId: this.projectid,
                notv: true,
            },
        );
    },
};
</script>
