<template>
    <div>
        <div class="k-flex-row">
            <label>Arborescences du projet :</label>
            <b-button
                size="sm"
                variant="outline-kalkin-2"
                v-b-modal.ModalCreateArbo
            >
                <b-icon-plus-circle /> Nouvelle arborescence
            </b-button>
        </div>
        <div class="k-panel-1">
            <div class="text-center m-2" v-if="!arbos.length">
                <small class="text-secondary">Aucune arborescence</small>
            </div>
            <div
                class="k-card-1"
                :class="{ selected: isArboUsed(arbo) }"
                v-else
                v-for="arbo in arbos"
                :key="arbo.id"
            >
                <div class="k-flex-row">
                    <b class="text-kalkin-2">
                        {{ arbo.name }}
                    </b>
                    <div class="text-right">
                        <b-button
                            class="k-btn-xs"
                            size="xs"
                            variant="outline-secondary"
                            @click="goEditArboStructure(arbo.id)"
                        >
                            <b-icon-arrow-right-circle /> Modifier
                        </b-button>
                    </div>
                </div>
                <div class="k-flex-row">
                    <small>
                        <div>
                            Modèle :
                            <span class="text-kalkin-1">
                                {{ getArboTemplate(arbo) }}
                            </span>
                        </div>
                        <div>
                            Structure :
                            <span class="text-kalkin-1">
                                {{ getArboStructure(arbo) }}
                            </span>
                        </div>
                    </small>

                    <b-button
                        v-if="!isArboUsed(arbo)"
                        size="xs"
                        variant="kalkin-1"
                        @click="onAddArbo(arbo.id)"
                    >
                        <b-icon-link /> Associer à l'application
                    </b-button>
                    <b-button
                        v-else
                        size="xs"
                        variant="danger"
                        @click="onDeleteArbo(arbo.id)"
                    >
                        <b-icon-x-circle /> Dissocier de l'application
                    </b-button>
                </div>
                <div v-if="isArboUsed(arbo)" class="k-flex-row">
                    <small class="d-block my-2 text-danger">
                        <b-icon-exclamation-triangle-fill /> Attention : En cas
                        de dissociation, tous les instantanés liés à cette
                        arborescence seront supprimés, ainsi que les styles liés
                        aux menus de cette arborescence.
                    </small>
                </div>
            </div>
        </div>

        <ModalDanger
            uid="TabArbos.delete"
            @confirmed="onDeleteConfirmed"
            message="Voulez vous vraiment dissocier cette arborescence de l'application?"
            details="Tous les instantanés et les styles liés à cette arborescence seront supprimés!"
        />
        <ModalCreateArbo
            :projectid="projectid"
            :applicationbuild="applicationbuild"
            @saved="fetchArbos"
        />
    </div>
</template>

<script>
import ModalDanger from "@/components/modal/ModalDanger";
import ModalCreateArbo from "@/components/model/arbo/ModalCreateArbo";
import { getArboTemplate, getArboStructure } from "@/model/arbo";

export default {
    components: {
        ModalDanger,
        ModalCreateArbo,
    },

    props: {
        projectid: [String, Number],
        configid: [String, Number],
        applicationbuild: Object,
        applicationarbos: Array,
    },

    data: function() {
        return {
            arbos: [],
            arboToRemove: null,
        };
    },

    computed: {
        usedArbos() {
            return this.applicationarbos.map(a => a.ArboId);
        },
    },

    methods: {
        getArboTemplate,
        getArboStructure,

        async fetchArbos() {
            this.arbos = await this.$store.dispatch("project/getArbos", {
                projectId: this.projectid,
            });
        },

        isArboUsed(arbo) {
            return this.usedArbos.includes(arbo.id);
        },

        goEditArboStructure(arboId) {
            this.$router.push({
                name: "AdminEditArboStructure",
                params: {
                    projectId: this.projectid,
                    arboId,
                },
            });
        },

        async onAddArbo(arboId) {
            await this.$store.dispatch("application/associateArboAppConfig", {
                projectId: this.projectid,
                configurationId: this.configid,
                arboId,
            });
            this.$emit("save");
        },

        async onDeleteArbo(arboId) {
            this.arboToRemove = arboId;
            this.$bvModal.show("TabArbos.delete");
        },

        async onDeleteConfirmed() {
            if (this.arboToRemove) {
                await this.$store.dispatch(
                    "application/removeAssociationArbo",
                    {
                        projectId: this.projectid,
                        configurationId: this.configid,
                        arboId: this.arboToRemove,
                    },
                );
                this.arboToRemove = null;
                this.$emit("save");
            }
        },
    },

    async beforeMount() {
        this.fetchArbos();
    },
};
</script>
