<template>
    <div class="text-left">
        <GenericForm
            class="m-0"
            v-bind="genericFormConfig"
            @validated="onValidate"
            @updated="localConfiguration = $event"
        />
    </div>
</template>

<script>
import Vue from "vue";
import GenericForm from "@/components/form-generator/GenericForm";
import Formatter from "@/tools/format";
import _ from "lodash";

export default {
    components: {
        GenericForm,
    },

    props: {
        applicationconfig: Object,
        applicationbuild: Object,
    },

    data: function() {
        const appConfig = _.get(this, "applicationconfig.config.app");
        return {
            localConfiguration: appConfig
                ? Vue.util.extend({}, appConfig)
                : Formatter.createDefaultFORMObject(this.createSchema()),
        };
    },

    computed: {
        genericFormConfig() {
            return {
                schema: this.createSchema(),
                model: this.localConfiguration,
            };
        },
    },

    methods: {
        createSchema() {
            try {
                return {
                    ...Formatter.convertCOREtoFORM(
                        this.applicationbuild.config.app.config,
                    ),
                };
            } catch (error) {
                return {};
            }
        },
        getPayload() {
            return {
                item: "ApplicationConfig",
                data: {
                    config: {
                        ...(this.applicationconfig.config || {}),
                        app: this.localConfiguration,
                    },
                },
            };
        },
        onValidate(value) {
            this.$parent.$emit("validate", value);
        },
    },
};
</script>
