<template>
    <b-modal
        id="ModalCreateArboSnapshot"
        size="xl"
        title="Créer un instantané"
        header-bg-variant="kalkin-1"
        header-text-variant="light"
        hide-footer
    >
        <FormArboSnapshot
            :projectid="projectid"
            :configid="configid"
            @saved="onSaved"
            class="mb-4"
        />
    </b-modal>
</template>
<script>
import FormArboSnapshot from "@/components/model/arbosnapshot/FormArboSnapshot";
export default {
    components: {
        FormArboSnapshot,
    },

    props: {
        projectid: { type: [String, Number], required: true },
        configid: { type: [String, Number], required: true },
    },

    methods: {
        onSaved() {
            this.$bvModal.hide("ModalCreateArboSnapshot");
            this.$emit("saved");
        },
    },
};
</script>
