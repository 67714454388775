<template>
    <div>
        <ArboNode :data="{ id: '', ...structure }" />
    </div>
</template>

<script>
import ArboNode from "./ArboNode.vue";
export default {
    components: {
        ArboNode,
    },
    props: {
        structure: Object,
    },
};
</script>

<style></style>
