<template>
    <div class="k-panel-1">
        <div>
            <LabelledInputText
                label="Nom"
                placeholder="Nom de l'arborescence"
                :required="true"
                v-model="localArbo.name"
                :forceValidation="true"
                @validated="isNameValid = $event"
                @input="hasChanged = true"
            />
        </div>

        <div v-if="!isEditMode">
            <b-form-radio-group class="radio-group" v-model="selectedStructure">
                <div class="k-radio-block" @click="selectedStructure = null">
                    <b-form-radio :value="null" />
                    <div>
                        <div class="text-kalkin-1">Arborescence vierge</div>
                        <small>Structure vide, pour un usage avancé</small>
                    </div>
                </div>
                <div
                    class="k-radio-block"
                    v-for="t in arboStructures"
                    :key="t.id"
                    @click="selectedStructure = t.id"
                >
                    <b-form-radio :value="t.id" />
                    <div>
                        <div class="text-kalkin-1">{{ t.name }}</div>

                        <small>{{ t.description }}</small>
                    </div>
                </div>
            </b-form-radio-group>
        </div>
        <div v-else>
            <label>Traductions</label>
            <div class="k-flex-col">
                <div class="k-flex-row" v-for="lang in langs" :key="lang.key">
                    <div class="k-flex-row k-card-1 flex-grow-1">
                        <span :class="`flag:${lang.key}`" />
                        {{ lang.name }}
                        <div style="flex:1" />
                        <div>
                            <small v-if="lang.locked">Langue par défaut</small>
                            <small class="text-kalkin-2" v-else-if="lang.add">
                                Ajout selectionné
                            </small>
                            <small class="text-danger" v-else-if="lang.remove">
                                Suppression selectionnée
                            </small>
                            <small v-else-if="lang.selected">Traduit</small>
                            <small v-else>Non traduit</small>
                        </div>
                    </div>
                    <div>
                        <b-button
                            v-show="
                                (!lang.locked && !lang.selected && !lang.add) ||
                                    lang.remove
                            "
                            size="xs"
                            variant="outline-kalkin-2"
                            @click="addTranslation(lang)"
                        >
                            <b-icon-plus-circle />
                        </b-button>
                        <b-button
                            v-show="
                                (!lang.locked &&
                                    lang.selected &&
                                    !lang.remove) ||
                                    lang.add
                            "
                            size="xs"
                            variant="outline-danger"
                            @click="removeTranslation(lang)"
                        >
                            <b-icon-dash-circle />
                        </b-button>
                    </div>
                </div>
            </div>
        </div>

        <div class="w-auto mt-3">
            <b-button
                class="mx-auto d-block"
                variant="kalkin-1"
                @click="save"
                :disabled="!isValid || !hasChanged"
            >
                <template v-if="isEditMode">
                    Enregistrer les modifications
                </template>
                <template v-else>
                    Créer l'arborescence
                </template>
            </b-button>
        </div>
    </div>
</template>

<script>
import Vue from "vue";
import Langs from "@/config/lang";
import LabelledInputText from "@/components/ui/item/LabelledInputText";

const defaultArbo = {
    name: undefined,
    structure: "Aucune",
};

export default {
    components: {
        LabelledInputText,
    },

    props: {
        projectid: { type: [String, Number], required: true },
        applicationbuild: { type: Object, required: false },
        arbo: { type: Object, required: false },
    },

    data: function() {
        return {
            arboStructures: this.initArboStructures(),

            selectedStructure: null,
            hasChanged: false,
            isEditMode: this.arbo != null,
            isNameValid: this.arbo != null && this.arbo.name,
            localArbo: Vue.util.extend({}, this.arbo || defaultArbo),
            translations: this.initTranslations(),
        };
    },

    computed: {
        langs() {
            return Langs.map(l => {
                l.locked = this.translations.locked.includes(l.key);
                l.selected = this.translations.selected.includes(l.key);
                l.add = this.translations.add.includes(l.key);
                l.remove = this.translations.remove.includes(l.key);
                return l;
            });
        },

        isValid() {
            return this.isNameValid;
        },
    },

    watch: {
        applicationbuild() {
            this.arboStructures = this.initArboStructures();
        },
    },

    methods: {
        initArboStructures() {
            const structures = [];
            try {
                const config = this.applicationbuild.TemplateConfig.config.arbo;
                for (const [id, value] of Object.entries(config)) {
                    if (id !== "default") {
                        structures.push({ id, ...value });
                    }
                }
            } catch (error) {
                // No template available
            }
            return structures;
        },

        initTranslations() {
            if (this.arbo) {
                let base = { locked: [], selected: [], add: [], remove: [] };
                for (const t of this.arbo.Langs) {
                    if (t.ArboTranslation.isDefault) {
                        base.locked.push(t.id);
                    } else {
                        base.selected.push(t.id);
                    }
                }
                return base;
            }
        },

        addTranslation(lang) {
            if (lang.selected) {
                this.translations.remove.splice(
                    this.translations.remove.indexOf(lang.key),
                    1,
                );
            } else {
                this.translations.add.push(lang.key);
            }
            this.hasChanged = true;
        },

        removeTranslation(lang) {
            if (lang.selected) {
                this.translations.remove.push(lang.key);
            } else {
                this.translations.add.splice(
                    this.translations.add.indexOf(lang.key),
                    1,
                );
            }
            this.hasChanged = true;
        },

        async save() {
            if (this.isEditMode) {
                await this.$store.dispatch("project/updateArbo", {
                    projectId: this.projectid,
                    arboId: this.arbo.id,
                    name: this.localArbo.name,
                });
                for (const langId of this.translations.add) {
                    await this.$store.dispatch("arbo/createTranslation", {
                        projectId: this.projectid,
                        arboId: this.arbo.id,
                        langId,
                    });
                }
                for (const langId of this.translations.remove) {
                    await this.$store.dispatch("arbo/deleteTranslation", {
                        projectId: this.projectid,
                        arboId: this.arbo.id,
                        langId,
                    });
                }
            } else {
                let structure;
                if (this.applicationbuild && this.selectedStructure) {
                    structure = {
                        applicationBuildId: this.applicationbuild.id,
                        structureId: this.selectedStructure,
                    };
                }
                await this.$store.dispatch("project/createArbo", {
                    projectId: this.projectid,
                    name: this.localArbo.name,
                    structure,
                });
            }
            this.$emit("saved");
            this.hasChanged = false;
        },
    },
};
</script>

<style lang="scss">
.k-radio-block {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    width: 100%;
    min-height: 70px;
    margin: 5px 0;
    border: 1px solid #aaa;
    border-radius: 6px;

    background: #eee;
    cursor: pointer;

    transition: background 0.3s ease-in-out;
}
.k-radio-block:hover {
    border-color: $primary-color;
    background: #ddd;
}

.k-radio-block > .custom-radio {
    margin: 0 5px 0 15px;
}

.k-radio-block .custom-control-input:checked ~ label::before {
    background-color: $primary-color;
    border-color: $primary-color-darker;
}
</style>
