<template>
    <div class="text-left">
        <GenericForm
            class="m-0"
            v-bind="genericFormConfig"
            @validated="onValidate"
            @updated="localStyle = $event"
        />
    </div>
</template>

<script>
import Vue from "vue";
import GenericForm from "@/components/form-generator/GenericForm";
import Formatter from "@/tools/format";

export default {
    components: {
        GenericForm,
    },

    props: {
        applicationconfig: { type: Object, required: true },
        applicationbuild: { type: Object, required: true },
        project: { type: Object, required: true },
    },

    data: function() {
        return {
            localStyle:
                this.applicationconfig && this.applicationconfig.style
                    ? Vue.util.extend({}, this.applicationconfig.style)
                    : Formatter.createDefaultFORMObject(this.createSchema()),
        };
    },

    computed: {
        genericFormConfig() {
            return {
                schema: this.createSchema(),
                model: this.localStyle,
                payload: { project: this.project },
            };
        },
    },

    methods: {
        createSchema() {
            try {
                return {
                    ...Formatter.convertCOREtoFORM(
                        this.applicationbuild.config.app.style,
                    ),
                };
            } catch (error) {
                return {};
            }
        },

        getPayload() {
            return {
                item: "ApplicationConfig",
                data: { style: this.localStyle },
            };
        },

        onValidate(value) {
            this.$parent.$emit("validate", value);
        },
    },
};
</script>
