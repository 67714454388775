<template>
    <div class="k-flex-col">
        <div>
            <h4><b-icon-bookmark-fill /> Instantané actif</h4>
        </div>
        <PanelDetailsArboSnapshot
            class="mb-4"
            :projectid="projectid"
            :arbosnapshot="activeSnapshot"
            @changed="onActiveArboSnapshotChanged"
        />

        <div class="k-flex-row">
            <h4>Liste des instantanés</h4>
            <b-button
                size="sm"
                variant="outline-success"
                v-b-modal.ModalCreateArboSnapshot
            >
                <b-icon-plus-circle /> Créer un instantané
            </b-button>
        </div>
        <ListArboSnapshot
            :projectid="projectid"
            :arbosnapshots="snapshots"
            :appconfigarbo="appconfigarbo"
            @changed="onArboSnapshotsChanged"
        />

        <ModalCreateArboSnapshot
            :projectid="projectid"
            :configid="appconfigarbo.id"
            @saved="onArboSnapshotsChanged"
        />
    </div>
</template>

<script>
import ModalCreateArboSnapshot from "@/components/model/arbosnapshot/ModalCreateArboSnapshot";
import ListArboSnapshot from "@/components/model/arbosnapshot/ListArboSnapshot";
import PanelDetailsArboSnapshot from "@/components/model/arbosnapshot/PanelDetailsArboSnapshot";

export default {
    components: {
        ModalCreateArboSnapshot,
        ListArboSnapshot,
        PanelDetailsArboSnapshot,
    },

    props: {
        projectid: [String, Number],
        configid: [String, Number],
        appconfigarbo: Object,
    },

    data: function() {
        return {
            snapshots: [],
            activeSnapshot: null,
        };
    },

    watch: {
        async appconfigarbo() {
            await this.fetchActiveSnapshot();
        },
    },

    methods: {
        async fetchSnapshots() {
            this.snapshots = await this.$store.dispatch(
                "arbosnapshot/getArboSnapshots",
                {
                    projectId: this.projectid,
                    acaId: this.appconfigarbo.id,
                },
            );
            await this.fetchActiveSnapshot();
        },

        async fetchActiveSnapshot(force = false) {
            const id = this.appconfigarbo.currentArboSnapshotId;
            if (id) {
                if (
                    !this.activeSnapshot ||
                    this.activeSnapshot.id !== id ||
                    force
                ) {
                    this.activeSnapshot = await this.$store.dispatch(
                        "arbosnapshot/getArboSnapshot",
                        {
                            projectId: this.projectid,
                            id,
                        },
                    );
                }
            }
        },

        async onArboSnapshotsChanged() {
            this.$emit("save");
            await this.fetchSnapshots();
        },

        async onActiveArboSnapshotChanged() {
            await this.fetchActiveSnapshot(true);
        },
    },

    async beforeMount() {
        await this.fetchSnapshots();
    },
};
</script>
