<template>
    <div v-if="arbosnapshot">
        <template v-for="builder in arbosnapshot.ArboBuilders">
            <b-card class="mb-2" no-body :key="builder.id">
                <template #header>
                    <div class="k-flex-row">
                        <b :class="`ml-2 flag:${builder.langId}`" />
                        Historique de génération
                        <div>
                            <b-button
                                variant="outline-secondary"
                                size="xs"
                                @click="createBuild(builder.id)"
                            >
                                <b-icon-plus-circle />
                                Nouvelle génération manuelle
                            </b-button>
                            <b-button
                                class="ml-1"
                                variant="outline-secondary"
                                size="xs"
                                @click="refresh"
                            >
                                <b-icon-arrow-repeat />
                            </b-button>
                        </div>
                    </div>
                </template>
                <GridCardBasic
                    :data="builder.ArboBuilds"
                    minCardSize="350px"
                    empty="Aucune génération"
                >
                    <template v-slot="{ data }">
                        <div class="k-card-1">
                            <h1>Génération #{{ data.id }}</h1>
                            <div class="k-flex-row">
                                <div class="k-flex-col">
                                    <div>
                                        <div>
                                            {{
                                                data.createdAt | momentSinceISO
                                            }}
                                        </div>
                                    </div>
                                    <div>
                                        <small>Etat : </small>

                                        <b-badge
                                            :variant="
                                                data.status | stateToVariant
                                            "
                                        >
                                            {{ data.status | stateToLabel }}
                                        </b-badge>
                                    </div>
                                </div>
                                <div>
                                    <b-button @click="goDetails(data.id)">
                                        Détails
                                    </b-button>
                                </div>
                            </div>
                        </div>
                    </template>
                </GridCardBasic>
            </b-card>
        </template>
    </div>
    <div v-else>
        Aucun instantané actif
    </div>
</template>

<script>
import GridCardBasic from "@/components/ui/GridCardBasic";

export default {
    components: {
        GridCardBasic,
    },

    props: {
        projectid: [String, Number],
        arbosnapshot: Object,
    },

    methods: {
        async createBuild(builderId) {
            await this.$store.dispatch(`arbosnapshot/createArboBuild`, {
                projectId: this.projectid,
                builderId,
            });
            this.refresh();
        },

        refresh() {
            this.$emit("changed");
        },

        goDetails(id) {
            this.$router.push({
                name: "AdminListArbobuilds",
                query: { search: id },
            });
        },
    },

    filters: {
        stateToLabel(state) {
            return [
                "Vierge",
                "En cours",
                "Echec",
                "Terminée",
                "A supprimer",
                "Partielle",
            ][state];
        },
        stateToVariant(state) {
            return [
                "light",
                "secondary",
                "danger",
                "success",
                "dark",
                "warning",
            ][state];
        },
    },
};
</script>
