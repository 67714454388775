module.exports = {
    getArboTemplate(arbo) {
        try {
            return arbo.config.template.name || "Aucun";
        } catch (error) {
            return "Non défini";
        }
    },

    getArboStructure(arbo) {
        try {
            return arbo.config.template.structure || "Aucune";
        } catch (error) {
            return "Non définie";
        }
    },

    getArboLangs(arbo) {
        if (Array.isArray(arbo.Langs) && arbo.Langs.length > 0) {
            // Cloning to avoid infinite loop within vue
            return [...arbo.Langs].sort(
                (x, y) =>
                    y.ArboTranslation.isDefault - x.ArboTranslation.isDefault,
            );
        } else {
            return [{ id: "fr", name: "Français" }];
        }
    },
};
